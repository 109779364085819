import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import WorkshopNavbarComponent from "./wsNavbar";
import WorkshopBannerCard from "./wsBannerCard";
import WorkshopDateTimeCard from "./wsDateTimeCard";
import WorkshopMeetMentorsCard from "./meetMentorsCard";
import WorkshopHighlights from "./wsHighlights";
import WorkshopFrequentlyAskedQuestion from "./wsFAQs";
import WorkshopFooter from "./wsFooter";
import workshopService from "../../services/workshopServices";
import WorkShopBannerImage from "../../assets/workshop_image_banner.png";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ComputerIcon from "@mui/icons-material/Computer";
import TranslateIcon from "@mui/icons-material/Translate";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import { useNavigate } from "react-router-dom";
import Mentor from "../../assets/mentor.png";

const WorkshopDetail = () => {
  const { workShopId } = useParams();
  const [workshopDetail, setWorkshopDetail] = useState(null);
  const [visible, setVisible] = useState([false, false, false, false]);
  const [storeAccessToken, setStoreAccessToken] = useState("");
  const navigate = useNavigate();
  const toggleVisibility = (index) => {
    const newVisible = [...visible];
    newVisible[index] = !newVisible[index];
    setVisible(newVisible);
  };

  const formatDateDays = (dateString) => {
    const date = new Date(dateString);
    const options = {
      weekday: "long",
      day: "2-digit",
      month: "short",
      year: "numeric",
    };
    return date.toLocaleDateString("en-GB", options);
  };

  const renderFAQItems = () => {
    return workshopDetail?.itemId?.additionalDetail?.faq?.map((item, index) => (
      <div
        key={index}
        style={{
          marginBottom: "30px",
          width: "100%",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <h6
          onClick={() => toggleVisibility(index)}
          className="ct-text-size-20 mb-2 text-dark"
        >
          {visible[index] ? "- " : "+ "} {item?.question.toUpperCase()}
        </h6>
        <div
          style={{
            maxHeight: visible[index] ? "1000px" : "0",
            overflow: "hidden",
            transition: "max-height 0.3s ease",
          }}
        >
          <p style={{ margin: 0, fontWeight: 500 }} className="ct-text-size-20">
            {item?.answer}
          </p>
        </div>
      </div>
    ));
  };

  const goToWorkshoplogin = () => {
    {
      storeAccessToken
        ? navigate(`/workshop/coupon/${workShopId}`)
        : navigate(`/workshop/login/${workShopId}`);
    }
  };

  useEffect(() => {
    const accessToken = localStorage.getItem("access_token");
    setStoreAccessToken(accessToken);
    getWorkshopDetail();
  }, [workShopId]);

  // const workshopDetail = {
  //   _id: "6719eca2c8f709c7877fc32f",
  //   itemId: {
  //     _id: "6718d05707a7a75604dba2cc",
  //     itemName: "Cinematography workshop",
  //     additionalDetail: {
  //       reponseMode: "FormResponse",
  //       maxFollowup: -1,
  //       maxCustomQuestions: -1,
  //       defaultImage: {
  //         type: "workshop_display_picture",
  //         visibility: "Public",
  //         media_id: "6681919249d9e2f2a88cc995",
  //         media_url:
  //           "https://tecxprt-media.sgp1.digitaloceanspaces.com/1719767442473Male%201.png",
  //       },
  //       meetingLink: "https://meet.google.com/uzp-cuzz-knc",
  //       registrationExpiry: "2024-10-30T15:25:25.519Z",
  //       mode: "online",
  //       workShopEndDate: "2024-11-02T15:25:25.519Z",
  //       workShopStartDate: "2024-10-31T15:25:25.519Z",
  //       endTime: "12:00 PM",
  //       startTime: "10:00 AM",
  //       faq: [
  //         {
  //           question: "sample qestion 2 ",
  //           answer: "sample answer 2",
  //         },
  //         {
  //           question: "sample qestion 3 ",
  //           answer: "sample answer 4",
  //         },
  //       ],
  //       highlights: [
  //         {
  //           icon: "local-fire-department",
  //           title: "expert guidance",
  //           body: "Powerful tool for aspiring talents - access to industry experts to get validation and feedback for their work",
  //         },
  //         {
  //           icon: "local-fire-department",
  //           title: "expert guidance",
  //           body: "Powerful tool for aspiring talents - access to industry experts to get validation and feedback for their work",
  //         },
  //         {
  //           icon: "local-fire-department",
  //           title: "expert guidance",
  //           body: "Powerful tool for aspiring talents - access to industry experts to get validation and feedback for their work",
  //         },
  //         {
  //           icon: "local-fire-department",
  //           title: "expert guidance",
  //           body: "Powerful tool for aspiring talents - access to industry experts to get validation and feedback for their work",
  //         },
  //         {
  //           icon: "local-fire-department",
  //           title: "expert guidance",
  //           body: "Powerful tool for aspiring talents - access to industry experts to get validation and feedback for their work",
  //         },
  //         {
  //           icon: "local-fire-department",
  //           title: "expert guidance",
  //           body: "Powerful tool for aspiring talents - access to industry experts to get validation and feedback for their work",
  //         },

  //       ],
  //     },
  //     price: 499,
  //     comparePrice: 600,
  //   },
  //   userId: "66ebeb8a9a3569c333693e52",
  //   language: [
  //     {
  //       languageName: "Tamil",
  //       languageId: "6091525efccd22ae080d9179",
  //       languageCode: "ta",
  //       _id: "66d6b42ff5d23dc51b763b28",
  //     },
  //     {
  //       languageName: "Telugu",
  //       languageId: "6091525efccd22ae080d917a",
  //       languageCode: "ta",
  //       _id: "66d6b42ff5d23dc51b763b28",
  //     },
  //   ],
  //   status: "Active",
  //   __v: 0,
  //   itemSold: 10,
  //   skill: {
  //     skillId: "66ebe9569a3569c333693e48",
  //     skill_name: "Cinematography",
  //   },
  //   respondTime: "3 days",
  //   type: "workShop",
  //   profileData: {
  //     _id: "66eefdd666f3dede9193b2c4",
  //     userName: "Kishore Kumar",
  //     is_verified: true,
  //     userId: "66ebeb8a9a3569c333693e52",
  //     media:
  //       "https://tecxprt-media.sgp1.digitaloceanspaces.com/1719767442473Male%201.png",
  //     visibility: "Public",
  //     profileId: "66eefdd666f3dede9193b2c4",
  //     displayName: "Kishore Kumar",
  //     about: "16 year exp in Cinematography",
  //     about2: "3 times National Award Winner",
  //   },
  // };

  const data = [
    {
      id: 1,
      title: "Date",
      date: formatDateDays(
        workshopDetail?.itemId?.additionalDetail?.workShopStartDate
      ),
      icon: <CalendarTodayIcon className="text-dark ct-text-30" />,
    },
    {
      id: 2,
      title: "Time",
      date:
        workshopDetail?.itemId?.additionalDetail?.startTime +
        " - " +
        workshopDetail?.itemId?.additionalDetail?.endTime,
      icon: <AccessTimeIcon className="text-dark ct-text-30" />,
    },
    {
      id: 3,
      title: "Mode",
      date: workshopDetail?.itemId?.additionalDetail?.mode,
      icon: <LaptopMacIcon className="text-dark ct-text-30" />,
    },
  ];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  };

  const getWorkshopDetail = async () => {
    const response = await workshopService.getServiceItemDetail(workShopId);
    setWorkshopDetail(response);
  };

  const calculateTimeDifference = (expiryDate) => {
    const now = new Date();
    const expiry = new Date(expiryDate);
    const difference = expiry - now;

    if (difference <= 0) {
      return "Expired";
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );

    return `${days} days ${hours} hours`;
  };

  const timeDifference = calculateTimeDifference(workshopDetail?.itemId?.additionalDetail?.registrationExpiry);
  const isRegistrationClosed = timeDifference === '0 days 0 hours' || timeDifference === 'Expired';

  return (
    <div className="">
      <div className="work-shop-background-image">
        <WorkshopNavbarComponent />
        <div className="landing-page-main main-div container landing-text-top">
          <div className=" ct-border-light-gray">
            <div className=" ct-display-flex-bottom">
              <div className="ct-bg-light ct-pad-20  ct-width-70">
                <div className="">
                  <h1 className="ct-text-size-60-Bold  mb-2">
                    {workshopDetail?.itemId?.itemName?.toUpperCase()}
                  </h1>
                  <div className="">
                    <div className="ct-gap-10 ct-display-flex pb-3 ct-grid">
                      <div className="ct-ws-badge-gray">
                        <CalendarTodayIcon
                          className="text-dark"
                          style={{ fontSize: 23 }}
                        />
                        <p className="margin-bottom-0rem border-title">
                          {formatDate(
                            workshopDetail?.itemId?.additionalDetail
                              ?.workShopStartDate
                          )}
                        </p>
                      </div>
                      <div className="ct-ws-badge-gray">
                        <ComputerIcon
                          className="text-dark"
                          style={{ fontSize: 25 }}
                        />
                        <p className="margin-bottom-0rem border-title">
                          {workshopDetail?.itemId?.additionalDetail?.mode}
                        </p>
                      </div>
                      <div className="ct-ws-badge-gray">
                        <TranslateIcon
                          className="text-dark"
                          style={{ fontSize: 25 }}
                        />
                        <p className="margin-bottom-0rem border-title">
                          {workshopDetail?.language?.[0]?.languageName}
                        </p>
                      </div>
                    </div>
                    <div className="mt-2 mbl-mb-20">
                      <h4 className="ct-text-size-50 ct-text-warning">
                        {workshopDetail?.itemId?.currency?.currency_code}{" "}
                        {workshopDetail?.itemId?.price}
                        <span
                          className="ct-text-size-30 ct-text-dark-gray"
                          style={{
                            textDecorationLine: "line-through",
                            paddingLeft: 10,
                            fontWeight: 600,
                          }}
                        >
                          {workshopDetail?.itemId?.comparePrice}
                        </span>
                      </h4>
                    </div>
                    <div className="ct-d-flex-justify-center ct-pr-30 mt-4 pt-3-5">
                      <div className="mbl-mb-20">
                        <div className="">
                          <a
                            className="my-3 apply-now-fill ct-pointer"
                            style={{ cursor: isRegistrationClosed ? 'not-allowed' : 'pointer', color: "#FFF" }}
                            onClick={() => !isRegistrationClosed && goToWorkshoplogin(workShopId)}
                          >
                            Register now
                          </a>
                        </div>
                      </div>
                      <div className="ipad-bottom">
                        <div className="">
                          <h5 className="ct-text-size-20 ct-text-dark-gray mb-2">
                            Registration closes in
                          </h5>
                          <h4
                            className="ct-text-size-30 ct-text-dark"
                            style={{ fontWeight: "500" }}
                          >
                            {timeDifference}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ct-width-30">
                <div className="">
                  <img
                    src={
                      workshopDetail?.itemId?.additionalDetail?.defaultImage
                        ?.media_url
                    }
                    alt="workshop poster"
                    className="image-card-ws"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="ct-space-evenly pt-5 mt-5 landing-page-main">
          {data.map((item) => (
            <div key={item.id} className="workshop-date-time-card m-2">
              <div>{item.icon}</div>
              <h4
                className="ct-text-size-30 text-dark"
                style={{ paddingTop: 12, marginBottom: 10 }}
              >
                {item.title}
              </h4>
              <h6 className="ct-text-size-24 ct-text-dark-gray">{item.date}</h6>
            </div>
          ))}
        </div>
      </div>


      <div className="ct-bg-light pt-5">
      <div className="pt-5 landing-page-main ct-bg-light container" id="highlights">
          <div className="head-div mx-auto ct-center-fixed pb-5">
            <section className="div-1 home-text-center">
              <h1 className="categories-title">WHAT YOU WILL LEARN?</h1>
            </section>
          </div>

          <div className="highlights-container ">
            {workshopDetail?.itemId?.additionalDetail?.highlights?.map(
              (item) => (
                <div key={item.id} className="highlight-card">
                  <img src={item?.icon} alt="icon" width={30} />
                  <h5 className="ct-text-size-24 ct-text-dark mb-2 pb-1 mt-2 pt-3" style={{fontWeight: 600, fontFamily: 'Gilroy-Bold'}}>
                    {item.title}
                  </h5>
                  <p className="ct-text-size-20 ct-text-dark">{item.body}</p>
                </div>
              )
            )}
          </div>
        </div>


        <div className="pt-5 landing-page-main container" id="mentor">
          <div className="head-div mx-auto ct-center-fixed pb-5">
            <section className="div-1 home-text-center">
              <h1 className="categories-title">MEET YOUR MENTOR </h1>
            </section>
          </div>
          <div className="mt-3 mentor-display-flex">
            <div className="ct-width-10"></div>
            <div className="mentor-display-flex ct-width-80">
              <div className="ct-mr-30">
                <img
                  src={workshopDetail?.profileData?.media}
                  alt="poster"
                  className="ct-mentor-img-size"
                />
              </div>
              <div className="mt-1 ct-mbl-mt-10">
                <h1 className="ct-text-size-50 ct-text-dark mb-2">
                  {workshopDetail?.profileData?.userName.toUpperCase()}
                </h1>
                <h3 className="ct-text-size-24 ct-text-dark mb-2">
                  {workshopDetail?.profileData?.about}
                </h3>
                <p className="ct-text-size-20 ct-text-dark">
                  {workshopDetail?.profileData?.about2}
                </p>
              </div>
            </div>
            <div className="ct-width-10"></div>
          </div>
        </div>


        <div className="mt-5 pt-5 container landing-page-main" id="faqs">
          <div className="head-div mx-auto ct-center-fixed pb-5">
            <section className="div-1 home-text-center">
              <h1 className="categories-title">FREQUENTLY ASKED QUESTIONS </h1>
            </section>
          </div>
          <div className="faqs-display-flex">
            <div className="ct-width-25"></div>
            <div className="ct-width-50">{renderFAQItems()}</div>
            <div className="ct-width-25"></div>
          </div>
        </div>
        <WorkshopFooter showSocialMediaLinks={true} />
      </div>
    </div>
  );
};

export default WorkshopDetail;

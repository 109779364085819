import React, { useState, useEffect } from "react";
import logo from "../../assets/ct_trans.png";
// import BGart from "../assets/BGart.png"
import { Fade } from "react-awesome-reveal";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import { IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import HighlightOffIcon from "@mui/icons-material/HighlightOffOutlined";
import Congratulations from "../../assets/firework2.png";
import playstore from "../../assets/playstore.png";
import appstore from "../../assets/app_store.png";
import QRCode from "../../assets/casttree_apps_QR.png";
import FooterData from "../footerData";
import WorkshopInfoDetail from "./workshopInfoDetail";
import workshopService from "../../services/workshopServices";
import { useParams } from "react-router-dom";

const WorkshopSuccess = () => {
  const navigate = useNavigate();
  const { workShopId } = useParams();
  const [workshopDetail, setWorkshopDetail] = useState(null);

  useEffect(() => {
    getWorkshopDetail();
  }, [workShopId]);

  const getWorkshopDetail = async () => {
    const response = await workshopService.getServiceItemDetail(workShopId);
    setWorkshopDetail(response);
  };

  const formatDateDays = (dateString) => {
    const date = new Date(dateString);
    const options = {
      weekday: "long",
      day: "2-digit",
      month: "short",
      year: "numeric",
    };
    return date.toLocaleDateString("en-GB", options);
  };
  

  return (
    <div className="work-shop-background-image">
      <div className="container">
        <div className="ct-p- ct-d-flex-justify-center-home">
          <Fade direction="left" cascade>
            <div className="ct-pointer" onClick={() => navigate("/")}>
              <img
                src={logo}
                alt="Casttree Logo"
                width={120}
                className="pl-5"
              />
            </div>
          </Fade>
        </div>
        <div className="mt-5 ct-bg-light">
          <div className="head">
            <div className="head-div ct-border-gray">
              <Fade direction="right" cascade>
                <div className="p-4">
                  <div className="ct-display-flex mb-2">
                    <div className="">
                      <img
                        src={Congratulations}
                        alt="Congratulations"
                        className="congratulations-img"
                      />
                    </div>
                    <div className="" style={{ paddingLeft: 20 }}>
                      <h1 className="ct-cirka-size-50 text-dark mb-2">
                        Congratulations!!!
                      </h1>
                      <div className="mt-2 mb-3">
                        <p className="ct-text-20 ct-text-dark">
                          You have successfully registered for the workshop
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="">
                    <h5
                      className="ct-text-size-30 ct-text-dark-gray mb-2"
                      style={{ fontWeight: "400", fontFamily: 'Gilroy-SemiBold' }}
                    >
                      Download{" "}
                      <span className="text-dark " style={{ fontWeight: "600", fontFamily: 'Gilroy-Bold' }}>
                        {" "}
                        Casttree App{" "}
                      </span>{" "}
                      to join the the workshop and stay updated about the
                      workshop
                    </h5>
                    <p className="ct-text-size-16 ct-text-dark-gray" style={{fontWeight: 500}}>
                      More details have been sent to your registered email ID
                      and mobile number
                    </p>
                  </div>

                  <div className="ct-d-flex-justify-center-web ct-flex-row mt-4">
                    <div className="event-second-section ct-border-gray">
                      <div
                        className="ct-bg-gray p-4 ct-border-radius-10"
                        style={{ borderWidth: 1, borderColor: "#6d6d6d" }}
                      >
                        <div className="text-center">
                          <img src={QRCode} width={150} />
                        </div>
                        <div className="text-center pt-3">
                          <h6
                            className="ct-text-size-24"
                            style={{ color: "#6d6d6d" }}
                          >
                            Download the{" "}
                            <span
                              className="text-dark"
                              style={{ fontWeight: "600" }}
                            >
                              {" "}
                              Casttree App
                            </span>{" "}
                            from the Play Store or the App Store
                          </h6>
                        </div>
                        <div className="ct-display-flex-mbl ct-justify-center pt-3">
                          <div className="">
                            <a
                              href="https://fv4b.short.gy/PKKWyx"
                              target="_blank"
                            >
                              <img
                                src={playstore}
                                width={150}
                                style={{ marginRight: 20 }}
                              />
                            </a>
                          </div>
                          <div className="">
                            <a
                              href="https://fv4b.short.gy/YCG6ws"
                              target="_blank"
                            >
                              <img
                                src={appstore}
                                width={150}
                                style={{ marginTop: 10 }}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="event-first-section ct-web-pl-30">
                      <div className="ct-text-left w-600px">
                        <div className="mt-3">
                          {/* <WorkshopInfoDetail workshopDetail={workshopDetail} /> */}
                          <div className="">
                            <div className="mb-3">
                              <h1 className="ct-text-size-30 ct-text-dark">
                                {workshopDetail?.itemId?.itemName?.toUpperCase()}
                              </h1>
                            </div>
                            <div className="mb-2">
                              <p className="ct-text-size-16 ct-text-dark-gray mb-1">
                                Date
                              </p>
                              <h1 className="ct-text-size-20 text-dark">
                              {formatDateDays(workshopDetail?.itemId?.additionalDetail?.workShopStartDate)}
                              </h1>
                            </div>
                            <div className="mb-2">
                              <p className="ct-text-size-16 ct-text-dark-gray mb-1">
                                Time
                              </p>
                              <h1 className="ct-text-size-20 text-dark">
                                {workshopDetail?.itemId?.additionalDetail?.startTime +
                                  " - " +
                                  workshopDetail?.itemId?.additionalDetail?.endTime}
                              </h1>
                            </div>
                            <div className="mb-2">
                              <p className="ct-text-size-16 ct-text-dark-gray mb-1">
                                Language
                              </p>
                              <h1 className="ct-text-size-20 text-dark">
                                {workshopDetail?.language?.[0]?.languageName}
                              </h1>
                            </div>
                            <div className="mb-2">
                              <p className="ct-text-size-16 ct-text-dark-gray mb-1">
                                Mode
                              </p>
                              <h1 className="ct-text-size-20 text-dark">
                                {workshopDetail?.itemId?.additionalDetail?.mode}
                              </h1>
                            </div>
                          </div>
                          {/* <div className="ct-d-flex-end">
                            <button
                              onClick={() => navigate("/")}
                              className="next-btn-small-dark mbl-my-4"
                              style={{ marginLeft: 15 }}
                            >
                              Go back home
                            </button>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>
      <FooterData />
    </div>
  );
};

export default WorkshopSuccess;

import React from "react";


const FooterData = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div
      className="container landing-page-main"
      id="applynow"
    >
      <div className="">
        <div className="head">
          <div
            className="ct-d-flex-justify-center mbl-mt"
            style={{ marginBottom: 10 }}
          >
            <div>
              <p className="copy-right-text text-dark mbl-pt" style={{ paddingLeft: 10 }}>
                Copyright © 2020-{currentYear} Billion faces technologies
                private limited. All rights reserved.
              </p>
            </div>
            <div className="mb-4">
              <p className="copy-right-text text-dark" style={{ paddingLeft: 10 }}>
                <span className="" style={{ marginRight: 20 }}>
                  <a
                    href="/refundPolicy"
                    target="_blank"
                    style={{ color: "#000" }}
                  >
                    Refund Policy
                  </a>
                </span>
                <span className="" style={{ marginRight: 20 }}>
                  <a
                    href="/privacyPolicy"
                    target="_blank"
                    style={{ color: "#000" }}
                  >
                    Privacy Policy
                  </a>
                </span>
                <span className="">
                  <a
                    href="/termsConditions"
                    target="_blank"
                    style={{ color: "#000" }}
                  >
                    Terms of Use
                  </a>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterData;

import React from "react";
import WorkshopNavbarComponent from "./wsNavbar";
import WorkshopBannerCard from "./wsBannerCard";
import WorkshopDateTimeCard from "./wsDateTimeCard";
import WorkshopMeetMentorsCard from "./meetMentorsCard";
import WorkshopHighlights from "./wsHighlights";
import WorkshopFrequentlyAskedQuestion from "./wsFAQs";
import WorkshopFooter from "./wsFooter";
import WorkshopListCard from "./workshopListCard";
import WorkshopListFilter from "./workshopListFilter";
import NavbarEmptyComponent from "../navbarEmpty";

const WorkshopMainList = () => {
  return (
    <div >
      <div className="ct-bg-light" style={{height: '100%',backgroundColor:"white"}}>
        <NavbarEmptyComponent />
        {/* <WorkshopListFilter/> */}
        <WorkshopListCard />
        <WorkshopFooter showSocialMediaLinks={true} />
      </div>
    </div>
  );
};

export default WorkshopMainList;

import React, { useEffect, useState } from "react";
import logo from "../../assets/ct_trans.png";
// import BGart from "../assets/BGart.png"
import { Fade } from "react-awesome-reveal";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import { IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import HighlightOffIcon from "@mui/icons-material/HighlightOffOutlined";
import FooterData from "../footerData";
import WorkshopInfoDetail from "./workshopInfoDetail";
import { useParams } from "react-router-dom";
import workshopService from "../../services/workshopServices";
import { APP_CONSTANTS, ENV } from "../../const/app.const";

const ENV_VARIABLES = APP_CONSTANTS[ENV];

const WorkshopCoupon = () => {
  const navigate = useNavigate();
  const [couponCode, setCouponCode] = useState("");
  const [couponStatus, setCouponStatus] = useState("");
  const { workShopId } = useParams();
  const [workshopDetail, setWorkshopDetail] = useState(null);
  const [applySection, setApplySection] = useState(true);
  const [discountedAmount, setDiscountedAmount] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [savedRequestId, setSavedRequestId] = useState(null);
  const [loadingProceedToPay, setLoadingProceedToPay] = useState(false);
  const [initProceedToPay, setInitProceedToPay] = useState(false);

  const handleCouponChange = (e) => {
    setCouponStatus("");
    const filteredText = e.target.value.replace(/[^a-zA-Z0-9]/g, "");
    setCouponCode(filteredText);
  };

  const navigateToSuccess = () => {
    navigate(`/workshop/success/${workShopId}`);
  };

  useEffect(() => {
    console.log("Coupon workShopId", workShopId);
    getWorkshopDetail();
  }, [workShopId]);

  const getWorkshopDetail = async () => {
    const response = await workshopService.getServiceItemDetail(workShopId);
    setWorkshopDetail(response);
  };

  const removeCoupon = () => {
    setDiscountedAmount(null);
    setCouponCode("");
    setCouponStatus("");
    setApplySection(true);
  };

  const applyCoupon = async () => {
    try {
      if (!couponCode) {
        setApplySection(true);
        setCouponStatus("Kindly enter coupon code");
        return;
      } else {
        setCouponStatus("");
        let payload = {
          sourceId: workshopDetail?.itemId?._id,
          sourceType: "serviceRequest",
          couponCode: couponCode?.toUpperCase(),
          module: "Feedback",
          billingAmount: workshopDetail?.itemId?.price,
        };

        let couponResponse = await workshopService.applyCoupon(payload);
        if (couponResponse && couponResponse?.discountAmount) {
          let discountedAmount = couponResponse?.discountAmount;
          setApplySection(false);
          setDiscountedAmount(discountedAmount);
        } else {
          setApplySection(true);
          setCouponStatus("Invalid coupon code");
        }
      }
    } catch (error) {
      if (error?.response?.status === 406 && error?.response?.data?.message) {
        setApplySection(true);
        setCouponStatus(error?.response?.data?.message);
      } else {
        setApplySection(true);
        setCouponStatus("Invalid coupon code");
      }
    }
  };

  const getTotalPaymentAmount = () => {
    const price =
      discountedAmount !== null
        ? parseFloat(
            (workshopDetail?.itemId?.price - (discountedAmount ?? 0)).toFixed(2)
          )
        : workshopDetail?.itemId?.price;
    return price;
  };

  const createServiceRequestAndPay = async () => {
    const user_id = localStorage.getItem("user_id");
    const userDetails = JSON.parse(localStorage.getItem("user_detail"));
    setIsButtonDisabled(true);
    try {
      const payload = {
        amount: workshopDetail?.itemId?.price,
        paymentMode: "Online",
        userId: user_id,
        serviceRequest: {
          type: "workShop",
          status: 'Initiated',
          itemId: workshopDetail?.itemId?._id,
          requestedToOrg: workshopDetail?.itemId?.orgId?._id,
          requestedToUser: workshopDetail?.profileData?.userId,
          requestedByOrg: userDetails?.organizationId,
        },
      };

      if (savedRequestId) {
        payload.serviceRequest.requestId = savedRequestId;
      }
      if (discountedAmount) {
        payload.discount = discountedAmount;
        payload.couponCode = couponCode?.toUpperCase();
      }
      const response = await workshopService.paymentRequestInitiate(payload);
      console.log("response Service Request", response);

      setSavedRequestId(response?.serviceRequest?.request?._id);
      initiateRazorpayPayment(response);
    } catch (error) {
      setIsButtonDisabled(false);
    }
  };

  const initiateRazorpayPayment = async (response: any) => {
    console.log('PAYEN', response)
    const rzp = new window.Razorpay({
      key: ENV_VARIABLES.RAZOR_PAY_KEY,
      currency: response?.paymentData?.currency_code,
      name: "Casttree",
      amount: response?.paymentData?.amount,
      order_id: response?.paymentData?.payment_order_id,
      theme: {
        color: "#3399cc",
      },
      handler: function (response) {
        return new Promise(async (resolve, reject) => {
          console.log("Payment success!", response);
          try {
            resolve();
            navigateToSuccess();
          } catch (error) {
            console.log("Payment failed or canceled!", error);
            setLoadingProceedToPay(false);
            setInitProceedToPay(false);
            setIsButtonDisabled(false);
            reject(error);
          }
        });
      },
      modal: {
        ondismiss: function () {
          setLoadingProceedToPay(false); // Enable the button if the payment window is closed
          setInitProceedToPay(false);
          setIsButtonDisabled(false);
        },
      },
    });
    rzp.open();

  };

  return (
    <div className="work-shop-background-image web-full-height">
      <div className="container">
        <div className="ct-p- ct-d-flex-justify-center-home">
          <Fade direction="left" cascade>
            <div className="ct-pointer" onClick={() => navigate("/")}>
              <img
                src={logo}
                alt="Casttree Logo"
                width={120}
                className="pl-5"
              />
            </div>
          </Fade>
        </div>
        <div className="ct-display-flex-mbl-unset pt-4">
          <div className="ws-card-first ct-bg-white ct-margin-20 mbl-mb-20">
            <WorkshopInfoDetail workshopDetail={workshopDetail} />
          </div>
          <div class="ws-card-second ct-bg-white">
            <div className="ct-web-position-relative">
              <div className="">
                <div className="pb-2">
                  <p className="ct-cirka-size-26 mb-1">
                    Tell us details about you{" "}
                  </p>
                  <p className="ct-text-size-14-gray">
                    Complete your purchase by providing your payment details.
                  </p>
                </div>
                <div>
                  <div className="mt-3 ct-coupon-border">
                    <div className="ct-display-flex">
                      <input
                        type="text"
                        placeholder="Enter coupon code"
                        className="ct-input-card"
                        value={couponCode.toUpperCase()}
                        autoCapitalize="characters"
                        onChange={handleCouponChange}
                        contentEditable={applySection}
                      />
                      {applySection && (
                        <span
                          className="gradient-text ct-float-right ct-pointer ct-ws-apply-coupon"
                          onClick={applyCoupon}
                        >
                          Apply
                        </span>
                      )}
                      {!applySection && (
                        <span
                          className="ct-float-right ct-text-size-16 ct-pointer ct-ws-remove-coupon text-danger"
                          onClick={removeCoupon}
                        >
                          Remove
                        </span>
                      )}
                    </div>
                    <div
                      className="pt-2 text-left"
                      style={{ textAlign: "left" }}
                    >
                      {couponStatus && (
                        <p className="ct-text-size-14 text-danger">
                          {couponStatus}
                        </p>
                      )}
                    </div>
                    <div className="ct-web-width-90 mt-4 ">
                      <div className="ct-display-flex ct-justify-space-between mb-2">
                        <h5 className="ct-text-size-16 ct-text-dark mb-2">
                          Total
                        </h5>
                        <h5 className="ct-text-size-16 ct-text-dark">
                          {workshopDetail?.itemId?.currency?.currency_code}{" "}
                          {workshopDetail?.itemId?.price}
                        </h5>
                      </div>
                      {discountedAmount && (
                        <div className="ct-display-flex ct-justify-space-between mb-2">
                          <h5 className="ct-text-size-16 ct-text-success mb-2">
                            Discount
                          </h5>
                          <h5 className="ct-text-size-16 ct-text-success">
                            {" "}
                            -{
                              workshopDetail?.itemId?.currency?.currency_code
                            }{" "}
                            {discountedAmount}
                          </h5>
                        </div>
                      )}
                      <div className="ct-display-flex ct-justify-space-between">
                        <h3 className="ct-text-size-25 ct-text-dark mb-2">
                          Grand Total
                        </h3>
                        <h3 className="ct-text-size-25 ct-text-dark">
                          {`${
                            workshopDetail?.itemId?.currency?.currency_code
                          } ${getTotalPaymentAmount()}`}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5 ct-web-position-absolute">
                <button
                  className="event-form-next-btn"
                  id="rzp-button1"
                  disabled={isButtonDisabled}
                  style={{
                    background:
                      "linear-gradient(to right, #EE35FF 0%, #FF8761 100%)",
                    color: "#fff",
                  }}
                  onClick={createServiceRequestAndPay}
                >
                  Complete Payment
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterData />
    </div>
  );
};

export default WorkshopCoupon;

import React from "react";
import { Fade } from "react-awesome-reveal";
import facebook from "../../assets/facebook.png";
import instagram from "../../assets/instagram.png";
import youtube from "../../assets/youtube.png";
import QRCode from "../../assets/qr_code_dark.png";
import playstore from "../../assets/playstore.png";
import appstore from "../../assets/app_store.png";

const WorkshopFooter = ({ showSocialMediaLinks }) => {
  const currentYear = new Date().getFullYear();
  return (
    <div
      style={{ background: "#FFF", color: "white" }}
      className="container mt-5 landing-page-main"
      id="applynow"
    >
      <div className="ct-top-30">
        <div className="head">
          <div className="head-div ">
            <Fade direction="up" cascade>
              <div className="ct-d-flex-justify-center ct-flex-row">
                {showSocialMediaLinks && (
                  <div className="">
                    <div className="mbl-text-left">
                      <h1 className="ct-title-social text-dark">Do you like our work?</h1>
                      <p className="ct-para-text">
                        If so, why not follow us on
                      </p>
                      <div className="">
                        <a
                          href="https://www.facebook.com/casttree"
                          target="_blank"
                        >
                          <img
                            src={facebook}
                            width={50}
                            style={{ marginRight: 20 }}
                          />
                        </a>
                        <a
                          href="https://www.instagram.com/casttree_com/"
                          target="_blank"
                        >
                          <img
                            src={instagram}
                            width={50}
                            style={{ marginRight: 20 }}
                          />
                        </a>
                        <a
                          href="https://www.youtube.com/@casttree"
                          target="_blank"
                        >
                          <img src={youtube} width={50} />
                        </a>
                      </div>
                    </div>
                  </div>
                )}

                <div className="ct-disply-flex">
                  <div className="footer-text-align mbl-pt">
                    <p className="ct-para-text text-dark" style={{ fontWeight: 400 }}>
                      Download the{" "}
                      <span style={{ fontWeight: 500, color: "#000" }}>
                        Casttree App
                      </span>{" "}
                      from the Play Store or the App Store
                    </p>
                    <div className="mbl-text-center">
                      <a
                        href="https://fv4b.short.gy/PKKWyx"
                        target="_blank"
                      >
                        <img
                          src={playstore}
                          width={150}
                          style={{ marginRight: 30 }}
                        />
                      </a>
                      <a href="https://fv4b.short.gy/YCG6ws" target="_blank">
                        <img src={appstore} width={150} />
                      </a>
                    </div>
                  </div>
                  <div className="ct-mbl-text-center">
                    <img src={QRCode} width={100} className="mbl-mr-30" />
                  </div>
                </div>
              </div>
            </Fade>
          </div>
          <div
            className="ct-d-flex-justify-center mbl-mt"
            style={{ marginBottom: 10 }}
          >
            <div>
              <p className="copy-right-text mbl-pt text-dark" style={{ paddingLeft: 10 }}>
                Copyright © 2020-{currentYear} Billion faces technologies
                private limited. All rights reserved.
              </p>
            </div>
            <div className="mb-4">
              <p className="copy-right-text text-dark" style={{ paddingLeft: 10 }}>
                <span className="" style={{ marginRight: 20 }}>
                  <a
                    href="/refundPolicy"
                    target="_blank"
                    style={{ color: "#000" }}
                  >
                    Refund Policy
                  </a>
                </span>
                <span className="" style={{ marginRight: 20 }}>
                  <a
                    href="/privacyPolicy"
                    target="_blank"
                    style={{ color: "#000" }}
                  >
                    Privacy Policy
                  </a>
                </span>
                <span className="">
                  <a
                    href="/termsConditions"
                    target="_blank"
                    style={{ color: "#000" }}
                  >
                    Terms of Use
                  </a>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkshopFooter;

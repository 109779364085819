import React from "react";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import Mentor from "../../assets/mentor.png";

const WorkshopInfoDetail = ({ workshopDetail }: { workshopDetail: any }) => {
  const formatDateDays = (dateString) => {
    const date = new Date(dateString);
    const options = {
      weekday: "long",
      day: "2-digit",
      month: "short",
      year: "numeric",
    };
    return date.toLocaleDateString("en-GB", options);
  };
  

  return (
    <div>
      <div className="">
        <div className="mb-3">
          <h1 className="ct-text-size-40 ct-text-dark">
            {workshopDetail?.itemId?.itemName?.toUpperCase()}
          </h1>
          <p className="ct-text-size-16 ct-text-dark-gray mb-2" style={{fontWeight: 400}}>
            By {workshopDetail?.profileData?.userName}
          </p>
          <h1 className="ct-text-size-40 ct-text-dark">
            {workshopDetail?.itemId?.currency?.currency_code}{" "}
            {workshopDetail?.itemId?.price}
          </h1>
        </div>
        <div className="mb-2">
          <p className="ct-text-size-18 mb-1">Date</p>
          <h1 className="ct-text-size-30">
            {formatDateDays(
              workshopDetail?.itemId?.additionalDetail?.workShopStartDate
            )}
          </h1>
        </div>
        <div className="mb-2">
          <p className="ct-text-size-18 mb-1">Time</p>
          <h1 className="ct-text-size-30">
            {workshopDetail?.itemId?.additionalDetail?.startTime +
              " - " +
              workshopDetail?.itemId?.additionalDetail?.endTime}
          </h1>
        </div>
        <div className="mb-2">
          <p className="ct-text-size-18 mb-1">Language</p>
          <h1 className="ct-text-size-30">
            {workshopDetail?.language?.[0]?.languageName}
          </h1>
        </div>
        <div className="mb-2">
          <p className="ct-text-size-18 mb-1">Mode</p>
          <h1 className="ct-text-size-30">
            {workshopDetail?.itemId?.additionalDetail?.mode}
          </h1>
        </div>
      </div>
    </div>
  );
};

export default WorkshopInfoDetail;

import React from "react";
import logoImage from "../assets/casttree_logo.png";
import EventImage from "../assets/home_event_img.png";
import { useNavigate } from "react-router-dom";
import Footer from "./footer";
import { Fade } from "react-awesome-reveal";
import SEO from "./seo";

const Home = () => {
  const navigate = useNavigate();
  const navigateToWorkshops = () => {
    navigate("/workshops");
  };
    const navigateToEvents = () => {
      navigate("/event");
    }
  return (
    <div className="landing-page-main">
      <SEO
        title="Casttree : Turn your passion into profession"
        description="Casttree is a platform where talents come together to make their passion into a profession."
      />
      <div className="ct-p-5 ct-d-flex-justify-center-home">
        <Fade direction="left" cascade>
          <div className="">
            <img
              src={logoImage}
              alt="Casttree Logo"
              width={120}
              className="pl-5"
            />
          </div>
        </Fade>
        <Fade direction="right" cascade>
          {/* <div className="ct-pointer">
            <img
              src={EventImage}
              alt="Casttree Event"
              width={200}
              className="pl-5 ct-pointer"
              onClick={navigateToEvents}
            />
          </div> */}
          <div className="ct-pointer" style={{display:"flex",gap:30,color:"rgba(255, 255, 255, 1)"}} >
            <p>About</p>
            <p onClick={navigateToEvents}>Events</p>
            <p onClick={navigateToWorkshops}>Workshops</p>
          </div>
        </Fade>
      </div>
      <div className="head landing-text-top head-div mx-auto">
        <Fade direction="down" cascade>
          <div className="" style={{ textAlign: "center" }}>
            <h1
              className="ct-home-title-100"
              style={{ color: "#FFF", paddingBottom: 20 }}
            >
              fueling dreams, <br />
              shaping futures
            </h1>
            <p className="ct-home-para">
              Casttree is a platform where talents come together to make their
              passion into a profession.
            </p>
          </div>
        </Fade>
      </div>
      <div className="" style={{ paddingTop: 45 }}>
        <Footer showSocialMediaLinks={true} />
      </div>
    </div>
  );
};

export default Home;
import React, { useEffect, useState } from "react";
import WorkShopBannerImage from "../../assets/workshop_image_banner.png";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ComputerIcon from "@mui/icons-material/Computer";
import TranslateIcon from "@mui/icons-material/Translate";
import { useNavigate } from "react-router-dom";
import workshopService from "../../services/workshopServices";

const WorkshopListCard = () => {
  const [workshopList, setWorkshopList] = useState([]);
  const navigate = useNavigate();

  const goToWorkshopMainLanding = (workShopId) => {
    navigate(`/workshop/${workShopId}`);
  };

  useEffect(() => {
    getWorkshopList();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  };

  const getWorkshopList = async () => {
    const response = await workshopService.getServiceItemList();
    setWorkshopList(response?.data);
  };

  const calculateTimeDifference = (expiryDate) => {
    const now = new Date();
    const expiry = new Date(expiryDate);
    const difference = expiry - now;

    if (difference <= 0) {
      return "Expired";
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );

    return `${days} days ${hours} hours`;
  };

  return (
    <div className="landing-page-main main-div container ct-bg-light landing-text-top">
      <div className="ct-bg-light">
        <div className="">
          <h1 className="ct-cirka-size-40 text-dark mb-5">Workshops</h1>
        </div>
        <div className="workshop-card-container">
          {workshopList?.map((workshop, index) => (
            <div key={index} className="workshop-card ct-border-light-gray">
              <div className="ct-display-flex-bottom ws-card-width-60">
                <div className="" style={{ paddingRight: 10, paddingLeft: 15 }}>
                  <div className="py-4">
                    <h1 className="ct-text-size-25 mb-3 text-dark">
                      {workshop?.itemId?.itemName?.toUpperCase()}
                    </h1>
                    <div className="">
                      <div className="ct-mbl-flex-start pb-3">
                        <div className="ct-ws-badge-gray-small">
                          <CalendarTodayIcon
                            className="text-dark"
                            style={{ fontSize: 15 }}
                          />
                          <h6
                            className="ct-text-size-11"
                            style={{ fontWeight: 600, paddingTop: 3 }}
                          >
                            {formatDate(
                              workshop?.itemId?.additionalDetail
                                ?.workShopStartDate
                            )}
                          </h6>
                        </div>
                        <div className="ct-ws-badge-gray-small">
                          <ComputerIcon
                            className="text-dark"
                            style={{ fontSize: 15 }}
                          />
                          <h6
                            className="ct-text-size-11"
                            style={{ fontWeight: 600, paddingTop: 3 }}
                          >
                            {workshop?.itemId?.additionalDetail?.mode}
                          </h6>
                        </div>
                        <div className="ct-ws-badge-gray-small">
                          <TranslateIcon
                            className="text-dark"
                            style={{ fontSize: 15, }}
                          />
                          <h6
                            className="ct-text-size-11"
                            style={{ fontWeight: 600, paddingTop: 3 }}
                          >
                            {workshop?.language[0]?.languageName}
                            {/* {workshop?.language?.map((lang, index) => (
                              <span key={lang?.languageId}>
                                {lang?.languageName}
                                {index < workshop.language.length - 1 && ", "}
                              </span>
                            ))} */}
                          </h6>
                        </div>
                      </div>
                      <div className="mbl-mb-20">
                        <h4 className="ct-text-size-30-bold  ct-text-warning">
                          {workshop?.itemId?.currency?.currency_code}{" "}
                          {workshop?.itemId?.price}
                          <span
                            className="ct-text-size-20-bold  ct-text-dark-gray"
                            style={{
                              textDecorationLine: "line-through",
                              paddingLeft: 10,
                              fontWeight: 600,
                            }}
                          >
                            {workshop?.itemId?.comparePrice}
                          </span>
                        </h4>
                      </div>
                      <div className="mt-4">
                        <div className="mbl-mb-20">
                          <h6 className="ct-text-size-16 ct-text-dark-gray mb-4">
                            Registration closes in
                            <span
                              className="ct-text-size-16 ct-text-dark"
                              style={{ fontWeight: 600 }}
                            >
                              {" "}
                              {calculateTimeDifference(
                                workshop?.itemId?.additionalDetail
                                  ?.registrationExpiry
                              )}
                            </span>
                          </h6>

                          <div className="">
                            <a
                              className="my-3 apply-now-fill-small ct-pointer"
                              style={{
                                cursor: "pointer",
                                color: "#FFF",
                                borderRadius: "px",
                                borderRadius: "50px",
                              }}
                              onClick={() =>
                                goToWorkshopMainLanding(workshop?._id)
                              }
                            >
                              View details
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ct-mbl-display-none ws-card-width-40">
                  <div className="ct-mbl-text-center">
                    <img
                      src={
                        workshop?.itemId?.additionalDetail?.defaultImage
                          ?.media_url
                      }
                      alt="workshop poster"
                      className="image-card-ws-card ct-mbl-border-radius-15 ct-web-border-radius-15"
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WorkshopListCard;

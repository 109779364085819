import React, { useEffect } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Fade } from "react-awesome-reveal";
import logoImage from "../../assets/ct_trans.png";
import { Link } from "react-scroll";
import { useNavigate } from "react-router-dom";
import authService from "../../services/authService";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";

const WorkshopNavbarComponent = () => {
  const navigate = useNavigate();
  const ctToken = localStorage.getItem("access_token");

  useEffect(() => {
    const handleScroll = () => {
      const header = document.querySelector(".header-nav");
      if (window.scrollY > 200) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleLogout = () => {
    authService.doLogout();
  };

  return (
    <>
      <Navbar
        className="header-nav navbar-dark workshop-landing-main"
        fixed="top"
        collapseOnSelect
        expand="lg"
        style={{backgroundColor: '#FFF'}}
      >
        <Fade direction="left" cascade>
          <Navbar.Brand
            className="brand-name ml-lg-5"
            style={{ paddingLeft: 30, cursor: "pointer" }}
            onClick={() => navigate("/")}
          >
            <img
              src={logoImage}
              alt="Casttree Logo"
              width={120}
              className="pl-5"
            />
          </Navbar.Brand>
        </Fade>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <ul
            className="navbar-nav ml-auto mr-lg-5 ml-lg-auto"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
              paddingRight: 30,
              fontWeight: "bold",
            }}
          >
            <Fade direction="right" cascade>
              <li className="nav-item">
                <Link
                  className="nav-link px-lg-3 ct-pointer"
                  to="mentor"
                  smooth={true}
                  duration={500}
                >
                  Mentor
                </Link>
              </li>
            </Fade>
            <Fade direction="right" cascade>
              <li className="nav-item">
                <Link
                  className="nav-link px-lg-3 ct-pointer"
                  to="highlights"
                  smooth={true}
                  duration={500}
                >
                  Highlights
                </Link>
              </li>
            </Fade>
            <Fade direction="right" cascade>
              <li className="nav-item">
                <Link
                  className="nav-link px-lg-3 ct-pointer"
                  to="faqs"
                  smooth={true}
                  duration={500}
                >
                  FAQs
                </Link>
              </li>
            </Fade>
          </ul>
          {ctToken && (
            <Nav>
              <Fade direction="right" cascade>
                <Nav.Link className="px-lg-3 ct-pointer" onClick={handleLogout}>
                  <LogoutOutlinedIcon /> 
                </Nav.Link>
              </Fade>
            </Nav>
          )}
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default WorkshopNavbarComponent;

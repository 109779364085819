import React, { useState, useEffect } from "react";
import logo from "../../assets/ct_trans.png";
// import BGart from "../assets/BGart.png"
import { Fade } from "react-awesome-reveal";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import { IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import HighlightOffIcon from "@mui/icons-material/HighlightOffOutlined";
import useResendTimer from "../useResendTimer";
import AuthService from "../../services/authService";
import toast, { Toaster } from "react-hot-toast";
import authService from "../../services/authService";
import FooterData from "../footerData";
import WorkshopInfoDetail from "./workshopInfoDetail";
import { useParams } from "react-router-dom";
import workshopService from "../../services/workshopServices";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const WorkshopLogin = () => {
  const navigate = useNavigate();
  const [workshopDetail, setWorkshopDetail] = useState(null);
  const { workShopId } = useParams();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isGeneratingOTP, setIsGeneratingOTP] = useState(false);
  const [isValidPhnNumber, setIsValidPhnNumber] = useState(false);
  const [resendTime, setResendTime] = useState(30);
  const [canResendOTP, setCanResendOTP] = useState(false);
  const [showOTPDialog, setShowOTPDialog] = React.useState(false);
  const [disableVerifyOTPBtn, setDisableVerifyOTPBtn] = useState(false);
  const { timer, resetTimer } = useResendTimer(30);
  const [otp, setOtp] = useState("");
  const [userData, setUser] = useState("");
  const authServices = AuthService;

  const handleInputChange = (event) => {
    let value = event.target.value.toString();
    value = value.replace(/\D/g, "");
    if (value.length > 10) {
      value = value.slice(0, 10);
    }
    setPhoneNumber(value);
    setIsValidPhnNumber(value.length === 10 && /^[6-9]\d{9}$/.test(value));
  };

  useEffect(() => {
    getWorkshopDetail();
  }, [workShopId]);

  const getWorkshopDetail = async () => {
    const response = await workshopService.getServiceItemDetail(workShopId);
    setWorkshopDetail(response);
  };

  const handleGenerateOTP = async () => {
    try {
      setIsGeneratingOTP(true);
      const response = await authService.generateOTP(phoneNumber.toString());
      setIsGeneratingOTP(false);
      setShowOTPDialog(true);
      startCountdown();
      toast.success("OTP generated successfully");
    } catch (error) {
      toast.error("Enter valid phone number");
      setIsGeneratingOTP(false);
    }
  };

  const goToWorkshopCreate = () => {
    navigate(`/workshop/create/${workShopId}`);
  };


  const loginOTPVerify = async () => {
    try {
      const otpResponse = await authServices.otpLogin(
        phoneNumber,
        otp.toString()
      );
      setDisableVerifyOTPBtn(true);
      const userData = otpResponse.user;
      const otpResponseData = otpResponse?.userOrganization[0].organizationId;
      localStorage.setItem("access_token", otpResponse.access_token);
      localStorage.setItem("user_detail", JSON.stringify(userData));
      localStorage.setItem(
        "user_otpResponse_data",
        JSON.stringify(otpResponseData)
      );
      localStorage.setItem("user_id", userData._id);
      setUser(userData);
      goToWorkshopCreate();
    } catch (error) {
      toast.error("Invalid OTP");
      setDisableVerifyOTPBtn(false);
    }
  };

  const startCountdown = () => {
    setCanResendOTP(false);
    const countDown = () => {
      setResendTime((prevTime) => {
        if (prevTime === 0) {
          setCanResendOTP(true);
          return 0;
        } else {
          return prevTime - 1;
        }
      });
      if (resendTime > 0) {
        setTimeout(countDown, 1000);
      }
    };
    countDown();
  };

  const handleClose = () => {
    setShowOTPDialog(false);
    setPhoneNumber("");
    startCountdown();
    resetTimer();
    setResendTime(0);
    window.location.reload();
  };

  const handleResendOTP = () => {
    if (timer === 0) {
      startCountdown();
      resetTimer();
      setShowOTPDialog(true);
      handleGenerateOTP();
      setResendTime(30);
      setOtp("");
    }
  };

  const handleOTPInputChange = (value) => {
    setOtp(value);
    if (value.length === 4) {
      setDisableVerifyOTPBtn(true);
    } else {
      setDisableVerifyOTPBtn(false);
    }
  };

  return (
<div style={{width:"100%",height:"110vh"}}>
<div className="work-shop-background-image" style={{ height: "100%" }}>
      <div className="container"> 
        <div className="ct-p- ct-d-flex-justify-center-home">
          <Fade direction="left" cascade>
            <div className="ct-pointer" onClick={() => navigate("/")}>
              <img
                src={logo}
                alt="Casttree Logo"
                width={120}
                className="pl-5"
              />
            </div>
          </Fade>
        </div>
        <div className="ct-display-flex-mbl-unset pt-4">
          <div className="ws-card-first ct-bg-white ct-margin-20 mbl-mb-20">
            <WorkshopInfoDetail workshopDetail={workshopDetail} />
          </div>
          <div class="ws-card-second ct-bg-white">
            <div className="pb-2">
              <p className="ct-cirka-size-26 mb-1">
                Tell us details about you{" "}
              </p>
              <p className="ct-text-size-14-gray">Give us details about you</p>
            </div>

            <div>
              <div className="mt-3">
                <p
                  className="copy-right-text mb-2"
                  style={{ fontWeight: 600, color: "#000" }}
                >
                  Mobile Number
                </p>
                <div className="ct-d-flex-mbl">
                  <input
                    type="number"
                    placeholder="enter your mobile number"
                    className="phone-no-input"
                    value={phoneNumber}
                    onChange={handleInputChange}
                  />

                  <button
                    onClick={handleGenerateOTP}
                    disabled={!isValidPhnNumber || isGeneratingOTP}
                    className="verify-btn"
                    style={{
                      background: isValidPhnNumber
                        ? "linear-gradient(to right, #EE35FF 0%, #FF8761 100%)"
                        : "gray",
                    }}
                  >
                    Verify
                  </button>
                </div>
              </div>
            </div>
            <BootstrapDialog
              aria-labelledby="customized-dialog-title"
              open={showOTPDialog}
            >
              <p
                className="ct-text-18 ct-text-weight-600 ct-text-dark p-3 pt-3"
                id="customized-dialog-title"
              >
                Enter OTP
              </p>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: "#000",
                }}
                className="ct-pointer"
              >
                <HighlightOffIcon className="ct-text-18 ct-text-dark" />
              </IconButton>
              <DialogContent dividers>
                <div className="dialog-custom-input">
                  <OtpInput
                    value={otp}
                    shouldAutoFocus
                    onChange={handleOTPInputChange}
                    numInputs={4}
                    isInputNum={true}
                    type="number"
                    inputStyle={{
                      width: "42px",
                      borderColor: "#B9B9B9",
                      height: "46px",
                      marginRight: "20px",
                      borderWidth: 1,
                      backgroundColor: "#F0F0F0",
                      borderRadius: 5,
                      fontSize: 20,
                    }}
                    className="customOtpContainer"
                    renderSeparator={<span></span>}
                    renderInput={(props) => (
                      <input
                        {...props}
                        inputMode="numeric"
                        type="number"
                        pattern="[0-9]*"
                      />
                    )}
                  />
                </div>
                <div className="mt-4 ct-text-center">
                  {timer > 0 ? (
                    <p
                      className="ct-text-14 mb-2"
                      style={{
                        fontWeight: 600,
                        color: "#000",
                      }}
                    >
                      You can resend code in{" "}
                      <span style={{ color: "#EE35FF" }}>
                        {" "}
                        {resendTime < 10 ? `0${resendTime}` : resendTime}{" "}
                        seconds{" "}
                      </span>
                    </p>
                  ) : (
                    canResendOTP && (
                      <p
                        className="ct-text-14 mb-2"
                        style={{
                          fontWeight: 600,
                          color: "#000",
                        }}
                      >
                        Didn't receive the OTP?{" "}
                        <span
                          onClick={handleResendOTP}
                          className="ct-pointer"
                          style={{ color: "#EE35FF" }}
                        >
                          Resend OTP
                        </span>
                      </p>
                    )
                  )}
                </div>
                <div className="mt-4 ct-text-center dialog-custom-input">
                  <p
                    className="ct-text-14 mb-2"
                    style={{ fontWeight: 600, color: "#000" }}
                  >
                    By clicking on verify, you accept to the{" "}
                    <span
                      className="ct-pointer"
                      style={{
                        textDecoration: "underline",
                        color: "#EE35FF",
                      }}
                    >
                      <a
                        href="/termsConditions"
                        target="_blank"
                        style={{ color: "#ee35ff" }}
                      >
                        terms and conditions
                      </a>
                    </span>{" "}
                    of Casttree events and awards
                  </p>
                </div>
                <div className="mt-4 ct-text-center">
                  <button
                    style={{
                      background: otp.length
                        ? "linear-gradient(to right, rgb(238, 53, 255) 0%, rgb(255, 135, 97) 100%)"
                        : "#C2C2C2",
                      color: otp.length ? "#FFF" : "#FFF",
                    }}
                    onClick={loginOTPVerify}
                    disabled={!disableVerifyOTPBtn}
                    className="next-btn-small mbl-my-4"
                  >
                    Verify
                  </button>
                </div>
              </DialogContent>
            </BootstrapDialog>
          </div>
        </div>
      </div>
      <FooterData />
    </div>
</div>
  );
};

export default WorkshopLogin;

// useResendTimer.js
import { useEffect, useState } from "react";

const useResendTimer = (initialValue = 0, interval = 1000) => {
  const [timer, setTimer] = useState(initialValue);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimer((prevTimer) => (prevTimer === 0 ? 0 : prevTimer - 1));
    }, interval);

    return () => clearInterval(timerInterval);
  }, [initialValue, interval]);

  const resetTimer = () => {
    setTimer(initialValue);
  };

  return { timer, resetTimer };
};

export default useResendTimer;

import axios from "axios";
import { CASTTREE_BASE_URL } from "../helper/video-uploader.helper";
import { APP_CONSTANTS, ENV } from "../const/app.const";

const ENV_VARIABLES = APP_CONSTANTS[ENV];

const CASTTREE_SERVICE_BASE_URL = ENV_VARIABLES.CASTTREE_SERVICE_BASE_URL;

// Prod
// const base_url = "https://prodapi.casttree.com";

const workshopService = {
  getServiceItemList: async (skip = 0, limit = 100) => {
    const response = await axios.get(
      `${CASTTREE_SERVICE_BASE_URL}/casttree-oms/service-item/workShop?skip=${skip}&limit=${limit}`
    );
    return response.data;
  },

  getServiceItemDetail: async (workshopId) => {
    const response = await axios.get(
      `${CASTTREE_SERVICE_BASE_URL}/casttree-oms/service-item/workShop/${workshopId}`
    );
    return response.data;
  },

  applyCoupon: async (requestBody: any) => {
    const token = localStorage.getItem("access_token");
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Assuming JSON data
    };
    const response = await axios.post(
      `${CASTTREE_SERVICE_BASE_URL}/casttree/coupon/apply-coupon`,
      requestBody,
      { headers }
    );
    return response.data;
  },

  paymentRequestInitiate: async (body: any) => {
    const token = await localStorage.getItem("access_token");
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const response = await axios.post(
      `${CASTTREE_SERVICE_BASE_URL}/casttree-oms/payment-request`,
      body,
      { headers }
    );
    return response.data;
  },

};

export default workshopService;

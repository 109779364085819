import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const UnProtectedRoute = ({ component }) => {
  const [hasAccess, setHasAccess] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const accessToken = localStorage.getItem("access_token");
    setHasAccess(accessToken ? true : false);
    if (accessToken) navigate("/workshop-coupon");
  }, [component]);
  return !hasAccess ? (
    <React.Fragment>{component}</React.Fragment>
  ) : (
    <React.Fragment/>
  );
};

export default UnProtectedRoute;